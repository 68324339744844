.pagination {
  display: inline-block;
  border-radius: 4px;
}
.pagination > a,
.pagination > span,
.pagination > em {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: $color;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
  font-style: normal;
  cursor: pointer;
}
.pagination > a:first-child,
.pagination > span:first-child {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.pagination > em {
  z-index: 0;
  color: #fff;
  background-color: $color;
  border-color: $color;
}
.pagination > a:hover,
.pagination > a:focus,
.pagination > span:hover,
.pagination > span:focus {
  z-index: 0;
  color: #23527c;
  background-color: #eeeeee;
  border-color: #ddd;
}
.pagination > span,
.pagination > span:hover,
.pagination > span:focus {
  color: #777777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}