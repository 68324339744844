$color: #007bff;
// $font-family-base: 'Monserat';
$font-family-base: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
@import "~bootstrap/scss/bootstrap";
@import "choices.js/src/styles/choices.scss";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "./pagination.scss";

// .card-columns {
//   @include media-breakpoint-only(xl) {
//     column-count: 2;
//   }
//   @include media-breakpoint-only(lg) {
//     column-count: 1;
//   }
//   @include media-breakpoint-only(md) {
//     column-count: 1;
//   }
//   @include media-breakpoint-only(sm) {
//     column-count: 1;
//   }
// }

.body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.table {
  margin-bottom: 0;
}

.card {
  border-radius: 0px;
  -webkit-box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  box-shadow: 0 1px 2.94px 0.06px rgba(4, 26, 55, 0.16);
  border-radius: 5px 0 5px 5px;
  margin-bottom: 30px;
}
.card-body {
  padding: 0.75rem 0.25rem 0.55rem 1.25rem;
}

.content {
  flex: 1;
  position: relative;
}

.filter {
  display: block;
}
.filter-hidden {
  display: none;
}

.expand-filter-text {
  color: $color;
  text-decoration: none;
  border-bottom: 1px dashed $color;
  margin-left: 0.3em;
  cursor: pointer;
}

#expand-filter {
  margin-bottom: 2px;
}
.btn-light {
  border: 1px solid #ced4da;
}

.course {
  .buttons {
    top: 0px;
    left: -45px;
    .btn {
      width: 40px;
      border-radius: 35px;
    }
  }
  .course_button_copy {
    margin-top: 15px;
  }
  .course_button_destroy {
    margin-top: 100px;
  }
  &:nth-of-type(n + 9) {
    .buttons {
      left: auto;
      right: -45px;
    }
  }
}

.card-infos2 {
  position: absolute;
  left: -1px;
  top: -25px;
}
.card-infos5 {
  position: absolute;
  left: 160px;
  top: -25px;
}
.card-infos3 {
  position: absolute;
  right: 50px;
  top: -25px;
}
.card-infos4 {
  position: absolute;
  right: 150px;
  top: -25px;
}
.card-infos {
  position: absolute;
  right: -1px;
  top: -25px;
}
.credits,
.status {
  background: #fff;
  padding: 0px 8px;
  border-radius: 5px 5px 0px 0px;
  text-align: center;
  float: left;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-style: none;
  min-height: 25px;
  display: flex;
  align-items: flex-end;
}
.credits > .text-info {
  margin-bottom: 5px;
}

#message {
  position: fixed;
  top: 10px;
  right: 0;
  // width: 100%;
  z-index: 5;
}
.inner-message {
  // margin: 0 auto;
  width: 300px;
}

.small_label {
  font-size: 0.9rem;
  font-weight: bold;
}

.btn {
  padding: 0.75rem;
}

.btn-sm {
  padding: 0.35rem;
}

.choices__list--dropdown, .choices__list[aria-expanded] {
  z-index: 10;
}

.choices__item.choices__item--choice.choices__item--selectable {
  min-height: 41px;
}

.choices__item.choices__item--selectable {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
// , .choices__list.choices__list--single
.choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
  padding-right: 0 !important;
}
.choices__inner {
  min-height: 46px;
}


.table-terms {
  width: auto;
  th {
    width: 34px;
  }
  th, td {
    height: 34px;
    vertical-align: middle;
  }
}

#courses_search {
  margin-bottom: 0;
}